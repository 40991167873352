.land-display {
  display: none;
}

.btn-danger {
  background-color: rgba(255, 86, 0, 1) !important;
  border-color: rgba(255, 86, 0, 1) !important;
}

.btn-danger:hover {
  background-color: rgba(186, 56, 0, 1) !important;
  border-color: rgba(186, 56, 0, 1) !important;
}

.btn-primary {
  background-color: rgba(117, 201, 255, 1) !important;
  border-color: rgba(117, 201, 255, 1) !important;
}

.btn-primary:hover {
  background-color: rgba(18, 165, 255, 1) !important;
  border-color: rgba(18, 165, 255, 1) !important;
}

.text-primary {
  color: rgba(18, 165, 255, 1) !important;
}

.btn-info {
  background-color: rgba(117, 201, 255, 1) !important;
  border-color: rgba(117, 201, 255, 1) !important;
}

.btn-info:hover {
  background-color: rgba(18, 165, 255, 1) !important;
  border-color: rgba(18, 165, 255, 1) !important;
}

.btn-success {
  background-color: rgba(17, 180, 52) !important;
  border-color: rgb(17, 180, 52) !important;
}

.btn-success:hover {
  background-color: rgb(47, 156, 32) !important;
  border-color: rgb(47, 156, 32) !important;
}

.text-sblue {
  color: rgba(0, 17, 28, 1) !important;
}

.text-sred {
  color: rgba(192, 0, 0, 1) !important;
}

.text-danger {
  color: rgba(255, 86, 0, 1) !important;
}

.text-inactive {
  color: rgba(191, 191, 191, 1);
}

.ct-red {
  color: rgba(192, 0, 0, 1) !important;
}

.btn-gray,
.btn-gray:hover {
  background-color: rgba(191, 191, 191, 1);
  border-color: rgba(191, 191, 191, 1);
}

/*Solución de padding en Modal*/
body {
  padding-right: 0 !important;
}

body.modal-open {
  padding-right: 0 !important;
}
body.modal {
  padding-right: 0 !important;
}

/*Solución de padding en Modal*/

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.section-dark {
  background-color: transparent !important;
}

.img-login {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  filter: grayscale(100%) brightness(90%);
}

.page-header {
  min-height: 80vh !important;
}

.margin-icon {
  margin-left: 5px;
}

.page-item.active .page-link {
  background-color: rgba(255, 86, 0, 1) !important;
  border-color: rgba(255, 86, 0, 1) !important;
  color: white !important;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border: 2px solid rgba(255, 86, 0, 1) !important;
  color: rgba(255, 86, 0, 1) !important;
}

/* Ribbon esquina superior derecha */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  /*border: 5px solid rgba(192, 0, 0, 1);*/
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 5px 0;
  background-color: rgba(192, 0, 0, 1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: 0px;
  right: 0px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* Ribbon esquina superior derecha */

.ribbon-card {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon-card span {
  font-size: 8.5px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: rgba(192, 0, 0, 1);
  background: linear-gradient(rgba(192, 0, 0, 1) 0%, rgba(192, 0, 0, 1) 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon-card span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid rgba(192, 0, 0, 1);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid rgba(192, 0, 0, 1);
}
.ribbon-card span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid rgba(192, 0, 0, 1);
  border-bottom: 3px solid transparent;
  border-top: 3px solid rgba(192, 0, 0, 1);
}

.ribbon-card.ribbon-new span {
  background: rgba(117, 201, 255, 1) !important;
  background: linear-gradient(
    rgba(117, 201, 255, 1) 0%,
    rgba(117, 201, 255, 1) 100%
  ) !important;
}

.ribbon-card.ribbon-new span::before {
  border-left: 3px solid rgba(117, 201, 255, 1) !important;
  border-top: 3px solid rgba(117, 201, 255, 1) !important;
}

.ribbon-card.ribbon-new span::after {
  border-right: 3px solid rgba(117, 201, 255, 1) !important;
  border-top: 3px solid rgba(117, 201, 255, 1) !important;
}

/* Ribbon esquina inferior derecha */

.propiedades-mapa {
  position: fixed;
  right: 0;
  top: 70px;
}

.capitol-logo {
  width: 700px;
  height: 150px;
}

.img-index {
  width: 140px;
  height: 140px;
  /*background-color: rgba(242, 242, 242);*/
  border-radius: 20% !important;
  padding: 20px;
}

.img-a {
  border-color: rgba(0, 17, 28, 1);
  border-style: solid;
}

.img-l {
  border-color: rgba(255, 86, 0, 1);
  border-style: solid;
}

.sub-Index {
  font-size: 30px;
  z-index: 2;
  color: black;
}

.u-land {
  border-bottom: solid rgba(255, 86, 0, 1);
}

.u-an {
  border-bottom: solid rgba(0, 17, 28, 1);
}

.heading-capitol {
  font-weight: bolder;
  padding-bottom: 10px;
}

.loc-line {
  border-bottom: 3px solid rgba(18, 165, 255, 1);
}

.empty-deck {
  width: 34%;
}

.empty-card {
  height: 500px;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.btn-credit {
  width: 350px;
  height: 50px;
  background-color: rgb(0, 17, 28);
  border-color: rgb(0, 17, 28);
}

.subTitleP {
  font-size: 16px;
  cursor: pointer;
  border: 2px solid rgba(117, 201, 255, 1);
  border-radius: 5px;
}

.selectedP {
  background-color: rgba(117, 201, 255, 1);
  color: #fff;
}

.subTitlePDng {
  font-size: 16px;
  cursor: pointer;
  border: 2px solid rgba(255, 86, 0, 1);
  border-radius: 5px;
}

.selectedPDng {
  background-color: rgba(255, 86, 0, 1);
  color: #fff;
}

.premiumChart {
  padding: "5px";
}

.react-player {
  border-radius: 0.7rem;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .col-mapa {
    display: none;
  }

  .search-box {
    top: auto;
    z-index: 0;
  }

  .legend-mapa {
    padding-top: 0 !important;
  }

  .propiedades-mapa {
    position: relative;
    right: auto;
    top: auto;
  }

  .color-bar {
    width: auto !important;
    padding-left: 5px !important;
  }

  .color-bar-text {
    font-size: 12px !important;
    text-align: center;
  }

  .color-bar-pad {
    padding-right: 0 !important;
  }

  .full-color-bar {
    font-size: 12px !important;
  }

  .display-4 {
    font-size: 30px;
  }

  .capitol-logo {
    width: 100%;
    height: 100%;
  }

  .empty-deck {
    width: 100%;
  }

  .btn-credit {
    width: 100%;
    height: 50px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.dropdown-menu .dropdown-item:hover {
  color: rgba(117, 201, 255, 1) !important;
  background-color: transparent !important;
}

.dropdown-item {
  color: white !important;
}

.dropdown-menu {
  background-color: transparent !important;
  margin-top: 15px !important;
}

.dropdown-menu.dd-white {
  background-color: white !important;
}

.dropdown-item.dd-white {
  color: #9a9a9a !important;
}

.dd-active {
  color: rgba(117, 201, 255, 1) !important;
}

@media screen and (max-width: 992px) {
  .dd-open {
    margin-bottom: 80px;
  }

  .dropdown-menu {
    overflow-y: hidden !important;
    margin-top: 5px !important;
  }

  .dropdown-item {
    color: #9a9a9a !important;
  }

  .dd-active {
    color: rgba(117, 201, 255, 1) !important;
  }
}

/* Animista Code  */

.blink-2 {
  -webkit-animation: blink-2 1.1s infinite both;
  animation: blink-2 1.1s infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-12 14:44:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-2
 * ----------------------------------------
 */
@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
